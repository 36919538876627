import CancelStepAccount from "../../components/CancelStepAccount";
import CancelStepAuth from "../../components/CancelStepAuth";
import CancelStepCanceled from "../../components/CancelStepCanceled";
import CancelStepFeedback from "../../components/CancelStepFeedback";
import CancelStepInstruction from "../../components/CancelStepInstruction";
import CancelStepOfferAccepted from "../../components/CancelStepOfferAccepted";
import CancelStepProgress from "../../components/CancelStepProgress";
import CancelStepPromo from "../../components/CancelStepPromo";
import CancelStepQuestion from "../../components/CancelStepQuestion";
import CancelStepReason from "../../components/CancelStepReason";
import CancelStepReminder from "../../components/CancelStepReminder";
import CancelStepSavings from "../../components/CancelStepSavings";
import CancelStepTheOffer from "../../components/CancelStepTheOffer";
import Layout, { PageBG } from "../../components/Layout";
import { FC, useEffect, useState } from "react";
import * as amplitude from "@amplitude/analytics-browser";
import { jwtDecode } from "jwt-decode";

export enum CANCEL_STEPS {
  AUTH = "AUTH",
  ACCOUNT = "ACCOUNT",
  PROMO = "PROMO",
  QUESTION = "QUESTION",
  REASON = "REASON",
  FEEDBACK = "FEEDBACK",
  REMINDER = "REMINDER",
  SAVINGS = "SAVINGS",
  PROGRESS = "PROGRESS",
  THEOFFER = "THEOFFER",
  CANCELED = "CANCELED",
  OFFER_ACCEPTED = "OFFER_ACCEPTED",
  INSTRUCTION = "INSTRUCTION",
}

export interface IUserData {
  billing_period: string;
  email: string;
  introductory_offer: string;
  paid_until: string;
  plan_type: string;
  subscription_status: string;
  supplements_discount: string;
  subscription: {
    id: string;
    amplitude: {
      braintree_user_id: string;
    };
  };
}

const Cancel: FC = () => {
  const [cancelStep, setCancelStep] = useState<CANCEL_STEPS>(CANCEL_STEPS.AUTH);

  const [email, setEmail] = useState<string>();
  // eyJhbGciOiAiUlMyNTYiLCAidHlwIjogIkpXVCIsICJraWQiOiAiZjI3MWU0ZGM3NjY4OGQ3YzEzNWRiN2MwZTI4YzU0YWE5NWZlNjBiOCJ9.eyJpc3MiOiAidGVzdGFjY0B0ZXN0ZWxpeGlyLmlhbS5nc2VydmljZWFjY291bnQuY29tIiwgInN1YiI6ICJ0ZXN0YWNjQHRlc3RlbGl4aXIuaWFtLmdzZXJ2aWNlYWNjb3VudC5jb20iLCAiYXVkIjogImh0dHBzOi8vaWRlbnRpdHl0b29sa2l0Lmdvb2dsZWFwaXMuY29tL2dvb2dsZS5pZGVudGl0eS5pZGVudGl0eXRvb2xraXQudjEuSWRlbnRpdHlUb29sa2l0IiwgInVpZCI6ICJQY05MMFNCQlNWYnlpTzBkenlIWDVHeTNEUU8yIiwgImlhdCI6IDE3Mzk4ODQ2NTksICJleHAiOiAxNzM5ODg4MjU5fQ.qRnUsIgbDMECMYyB6Gra1f4JaBCrEFC68HqraSjvXmWhiBKFBJW6XTXz3pknT2BOeuCU619YytAFJvodEef_Z67BWHwV9DZvLFHyqiDC4Yn7oVXXS6hWcBKcUkmnxAtH1HgHyQNhqGj3UaodB7kxyolCfrFDaD8RwsnTqIyzB-dUB9VWfACDUsijkgf7Lf-PPlHvNerXz8Y0gOtLvgL5IANc_Uz6rEgIzmnSUrtt_C1u9SJyiIqISULwbORJs4AKQ6z6pXVKt2g7G4v4-Kg2-jt9qVEOL6aqiP0KyML1k0TGMKeDs1UCRSBCZxyd9ca17cZK7E0BC6nq5AVZ1IK0hw
  const [token, setToken] = useState<string>();
  const [user_id, setUserId] = useState<string>();
  // PcNL0SBBSVbyiO0dzyHX5Gy3DQO2
  const [userUid, setUserUid] = useState<string>();
  const [userData, setUserData] = useState<IUserData>();

  const onSetEmail = (email: string) => {
    setEmail(email);
  };

  const onSetToken = (token: string) => {
    setToken(token);
  };

  const onNextStep = (step: CANCEL_STEPS) => {
    setCancelStep(step);
  };

  const onBackToApp = () => {
    // @ts-ignore
    const isAppleWebview = window.webkit && window.webkit.messageHandlers;

    if (isAppleWebview) {
      // @ts-ignore
      webkit.messageHandlers.callbackHandler.postMessage("webview-back");
      return;
    }

    // @ts-ignore
    const isAndroidWebview = window.callbackHandler;

    if (isAndroidWebview) {
      // @ts-ignore
      window.callbackHandler.postMessage("webview-back");
      return;
    }
  };

  const contactUs = (theme: string) => {
    window.location.href = `https://webview-dev.the.coach/app-ticket.html?userid=${user_id}&category=${theme}&email=${email}`;
  };

  useEffect(() => {
    if (token) {
      const data: { uid: string } = jwtDecode(token);
      setUserUid(data.uid);
    }
  }, [token]);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const user_id = queryParams.get("user_id") || queryParams.get("userid");
    setUserId(user_id as string);
  }, []);

  useEffect(() => {
    amplitude.init("4c1bac9fb3c72e49a3cd424024f5904f");
  }, [])

  useEffect(() => {
    if (user_id) {
      switch (cancelStep) {
        case CANCEL_STEPS.ACCOUNT:
          amplitude.track({
            event_type: "Cancel_SubDetails_open",
            user_id: user_id,
          });
          break;
        case CANCEL_STEPS.PROMO:
          amplitude.track({
            event_type: "Cancel_maininfo_open",
            user_id: user_id,
          });
          break;
        case CANCEL_STEPS.PROGRESS:
          amplitude.track({
            event_type: "Cancel_seeresult_open",
            user_id: user_id,
          });
          break;
        case CANCEL_STEPS.SAVINGS:
          amplitude.track({
            event_type: "Cancel_tooexpensive_open",
            user_id: user_id,
          });
          break;
        case CANCEL_STEPS.REMINDER:
          amplitude.track({
            event_type: "Cancel_forgetcancel_open",
            user_id: user_id,
          });
          break;
        case CANCEL_STEPS.FEEDBACK:
          amplitude.track({
            event_type: "Cancel_otherreason_open",
            user_id: user_id,
          });
          break;
        case CANCEL_STEPS.THEOFFER:
          amplitude.track({
            event_type: "Cancel_first_so_open",
            user_id: user_id,
          });
          break;
        case CANCEL_STEPS.CANCELED:
          amplitude.track({
            event_type: "Cancel_second_so_open",
            user_id: user_id,
          });
          break;
        case CANCEL_STEPS.OFFER_ACCEPTED:
          amplitude.track({
            event_type: "Cancel_congrats_open",
            user_id: user_id,
          });
          break;
      }
    }
  }, [cancelStep, user_id]);

  const getStep = () => {
    switch (cancelStep) {
      case CANCEL_STEPS.AUTH:
        return (
          <Layout pageBG={PageBG.CANCALATION}>
            <CancelStepAuth
              onNextStep={onNextStep}
              onSetEmail={onSetEmail}
              onSetToken={onSetToken}
              user_id={user_id || ""}
              contactUs={contactUs}
            />
          </Layout>
        );
      case CANCEL_STEPS.ACCOUNT:
        return (
          <Layout pageBG={PageBG.CANCALATION}>
            <CancelStepAccount
              setUserData={setUserData}
              userUid={userUid}
              onNextStep={onNextStep}
              userData={userData}
              user_id={user_id}
            />
          </Layout>
        );
      case CANCEL_STEPS.PROMO:
        return (
          <Layout pageBG={PageBG.CANCEL_PROMO}>
            <CancelStepPromo
              onNextStep={onNextStep}
              onBackToApp={onBackToApp}
            />
          </Layout>
        );
      case CANCEL_STEPS.QUESTION:
        return (
          <Layout pageBG={PageBG.CANCALATION}>
            <CancelStepQuestion
              onBackToApp={onBackToApp}
              onNextStep={onNextStep}
              user_id={user_id}
            />
          </Layout>
        );
      case CANCEL_STEPS.REASON:
        return (
          <Layout pageBG={PageBG.CANCALATION}>
            <CancelStepReason
              onBackToApp={onBackToApp}
              onNextStep={onNextStep}
              user_id={user_id}
            />
          </Layout>
        );
      case CANCEL_STEPS.FEEDBACK:
        return (
          <Layout pageBG={PageBG.CANCALATION}>
            <CancelStepFeedback
              onBackToApp={onBackToApp}
              onNextStep={onNextStep}
              user_id={user_id || ""}
              email={email || ""}
            />
          </Layout>
        );
      case CANCEL_STEPS.REMINDER:
        return (
          <Layout pageBG={PageBG.CANCALATION}>
            <CancelStepReminder
              userData={userData}
              user_id={user_id}
              onNextStep={onNextStep}
              onBackToApp={onBackToApp}
            />
          </Layout>
        );
      case CANCEL_STEPS.SAVINGS:
        return (
          <Layout pageBG={PageBG.CANCALATION}>
            <CancelStepSavings
              onBackToApp={onBackToApp}
              onNextStep={onNextStep}
            />
          </Layout>
        );
      case CANCEL_STEPS.PROGRESS:
        return (
          <Layout pageBG={PageBG.CANCALATION}>
            <CancelStepProgress
              onBackToApp={onBackToApp}
              onNextStep={onNextStep}
              contactUs={contactUs}
            />
          </Layout>
        );
      case CANCEL_STEPS.THEOFFER:
        return (
          <Layout pageBG={PageBG.CANCEL_PROMO}>
            <CancelStepTheOffer
              onNextStep={onNextStep}
              onBackToApp={onBackToApp}
              user_id={user_id || ""}
              userData={userData}
            />
          </Layout>
        );
      case CANCEL_STEPS.CANCELED:
        return (
          <Layout pageBG={PageBG.CANCEL_PROMO}>
            <CancelStepCanceled
              onNextStep={onNextStep}
              onBackToApp={onBackToApp}
              userData={userData}
              user_id={user_id}
            />
          </Layout>
        );
      case CANCEL_STEPS.OFFER_ACCEPTED:
        return (
          <Layout pageBG={PageBG.OFFER_ACCEPTED}>
            <CancelStepOfferAccepted
              onBackToApp={onBackToApp}
              onNextStep={onNextStep}
            />
          </Layout>
        );
      case CANCEL_STEPS.INSTRUCTION:
        return (
          <Layout pageBG={PageBG.CANCALATION}>
            <CancelStepInstruction />
          </Layout>
        );
    }
    return <></>;
  };

  return getStep();
};

export default Cancel;
