import { FC, useState, useEffect } from "react";

import usePlans from "../../hooks/usePlans";
import Header from "../../components/Header";
import Title from "../../components/Title";
import Results from "../../components/Results";
import Loading from "../../components/Loading";

import styles from "./styles.module.scss";
import Layout, { PageBG } from "../../components/Layout";
import { BottomSheet } from "react-spring-bottom-sheet";
// import { useIntercom } from "react-use-intercom";
import ReactGA from "react-ga4";
import SpecialOfferModal from "../../components/SpecialOfferModal";
import disocuntTitleImg from "./../../../assets/titleTiscount.png";
import Menu from "../../components/Menu";
import Divider from "../../components/Divider";
import Plan from "../../components/Plans";
import Benefits from "../../components/Benefits";
import ReviewsAndRatings from "../../components/ReviewsAndRatings";
import MoneyBack from "../../components/MoneyBack";
import Payment from "../../components/Payment";
import {
  getQuestionanireFlow,
  getQuestionnaireTheme,
  QUESTIONNAIRE_FLOW,
  QUESTIONNAIRE_THEME,
} from "../Questionnaire";
import useBackendLog from "../../hooks/useBackendLog";
import BenefitsExtended from "../../components/BenefitsExtended";
import cls from "classnames";
import { useTranslation } from "react-i18next";

interface IText {
  title: string;
  description: string;
  results_title: string;
  benefits?: string[];
  benefits_extended?: {
    title: string;
    description: string;
  }[];
  reviews: {
    star: number;
    text: string;
    userName: string;
  }[];
}

const man_flow: IText = {
  title: "paywall_fix_mens_health",
  description: "paywall_fix_mens_health_sub",
  results_title: "paywall_long_lasting_results",
  benefits: [
    "paywall_benefit_1",
    "paywall_benefit_2",
    "paywall_benefit_3",
    "paywall_benefit_4",
    "paywall_benefit_5",
  ],
  reviews: [
    {
      star: 5,
      text: "paywall_review_1",
      userName: "paywall_review_1_author",
    },
    {
      star: 5,
      text: "paywall_review_2",
      userName: "paywall_review_2_author",
    },
    {
      star: 5,
      text: "paywall_review_3",
      userName: "paywall_review_3_author",
    },
    {
      star: 5,
      text: "paywall_review_4",
      userName: "paywall_review_4_author",
    },
  ],
};
const woman_flow: IText = {
  title: "REIGNITE YOUR SEXUAL DESIRE",
  description: "Gain confidence and deepen connection in your intimate life",
  results_title: "Enhance your libido and improve your s-life",
  benefits: [
    "Complete guidance across five areas of sexual wellbeing to enhance desire",
    "Evidence-based practices to deepen intimacy and pleasure",
    "Tools for emotional well-being to build self-confidence",
    "Expert-led lessons tailored for women’s needs",
    "Relaxation exercises and meditations to spark connection and desire",
  ],
  reviews: [
    {
      star: 5,
      text: "“I can't thank this program enough. It helped me reconnect with myself and my partner in ways I never thought possible. I feel more confident and in tune with my desires. It truly changed my life!”",
      userName: "Sophia M..",
    },
    {
      star: 5,
      text: "“I didn’t realize how much stress and routine impacted my libido until I started this program. It helped me take control of my sexual well-being, and I’ve never felt more empowered.”",
      userName: "Isabella R.",
    },
    {
      star: 5,
      text: "“Joining this program was one of the best decisions I've made for myself. The practical advice and meditations helped me rebuild intimacy in my relationship. Forever grateful!”",
      userName: "Olivia T.",
    },
    {
      star: 5,
      text: "“This program was exactly what I needed. The lessons and exercises helped me understand my barriers to desire and gave me tools to overcome them. I feel more alive and connected.”",
      userName: "Emma L.",
    },
  ],
};
const woman_flow_qs: IText = {
  title: "it’s time to Come first. always.",
  description: "Gain confidence and deepen connection in your intimate life",
  results_title: "It’s a unique opportunity to take your s-life to a new level",
  benefits_extended: [
    {
      title: "Deepen Your Connection",
      description:
        "Strengthen emotional and physical intimacy with expert-backed techniques.",
    },
    {
      title: "Experience True Satisfaction",
      description:
        "Learn how to communicate your desires and fully enjoy every moment.",
    },
    {
      title: "Bring Passion Back",
      description:
        "Add excitement, adventure, and spontaneity to your intimate life.",
    },
    {
      title: "Be the Best for Him",
      description:
        "Build confidence, embrace your sensuality, and feel truly irresistible.",
    },
    {
      title: "Guide Him to Please You",
      description:
        "Teach him what you love so he can fulfill your deepest desires.",
    },
  ],
  reviews: [
    {
      star: 5,
      text: "“This program completely changed the way I connect with my partner. I used to feel distant, like we were just going through the motions. Now, I understand how to truly be present, emotionally and physically. The difference is unbelievable!”",
      userName: "Olivia M.",
    },
    {
      star: 5,
      text: "“I didn’t even realize how much I was missing until I started this program. I’ve learned to ask for what I want and actually enjoy intimacy instead of just ‘going along’ with it.”",
      userName: "Jane K.",
    },
    {
      star: 5,
      text: "“OMG, this was exactly what I needed! Things had gotten... let’s just say, boring. But now? I feel excited again. Like when we first started dating. If you’re feeling stuck, TRY THIS!”",
      userName: "r00sespike99",
    },
    {
      star: 5,
      text: "“I always thought my partner should just ‘know’ what to do. But let’s be real—he’s not a mind reader. This program gave me the confidence to guide him, and the results? Let’s just say he’s VERY eager to learn!”",
      userName: "Elliot M.",
    },
  ],
};

const Plans: FC = () => {
  const { t } = useTranslation();
  const { plans, loading, getPlansRequest } = usePlans();
  const [meunOpen, setMenuOpen] = useState<boolean>(false);
  const flow = getQuestionanireFlow();
  // const { boot, show } = useIntercom();

  const [isPaymentinProgress, setIsPaymentProgress] = useState(false);
  const [isPaymentFailed, setIsPaymentFailed] = useState<boolean>(false);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState<boolean>(false);
  const [isSpecialOfferModalOpen, setIsSpecialOfferModalOpen] =
    useState<boolean>(false);
  const [selectedPlan, setSelectedPlan] = useState<number>(0);
  const { onBackendLog } = useBackendLog();
  const theme = getQuestionnaireTheme();
  const isWhite = theme === QUESTIONNAIRE_THEME.WHITE;

  let current_flow_text: IText = man_flow;

  switch (flow) {
    case QUESTIONNAIRE_FLOW.WOMAN_FLOW_DESIRE_1:
      current_flow_text = woman_flow;
      break;
    case QUESTIONNAIRE_FLOW.WOMAN_FLOW_COMEFIRST_1:
      current_flow_text = woman_flow_qs;
      break;
  }

  const handleMenuToggle = () => setMenuOpen(!meunOpen);
  const handlePaymentOpen = () => {
    ReactGA.gtag("event", "select_plat_tap", {});

    onBackendLog({
      type: "get_my_plans_clicked",
    });

    window.ttq.track("InitiateCheckout");

    setIsPaymentModalOpen(true);
  };

  const covertPwToSoAndRedirect = () => {
    const url = new URL(window.location.href);
    const so = url.searchParams.get("so") as string;
    url.searchParams.delete("so");

    if (so) {
      url.searchParams.set("pw", so);
    }

    window.history.replaceState({}, "", url.toString());
  };

  const handlePaymentClose = () => {
    setIsPaymentModalOpen(false);

    const queryParams = new URLSearchParams(window.location.search);
    const so = queryParams.get("so");

    if (so) {
      setIsSpecialOfferModalOpen(true);
      window.scrollTo(0, 0);

      const queryParams = new URLSearchParams(window.location.search);
      const so = queryParams.get("so");

      if (so) {
        localStorage.setItem("discount_applied", so);
        covertPwToSoAndRedirect();
        getPlansRequest(so);
      }

      ReactGA.gtag("event", "special_offer_view", {});
    }
  };

  const handleSpecialOfferClose = () => {
    setIsSpecialOfferModalOpen(false);
  };

  // useEffect(() => {
  //   if (isPaymentFailed) {
  //     boot && boot();
  //     show && show();
  //   }
  // }, [isPaymentFailed, boot, show]);

  useEffect(() => {
    window.scrollTo(0, 0);

    window.ttq.track("ViewContent");
  }, []);

  useEffect(() => {
    if (plans) {
      onBackendLog({ type: "plans_loaded" });
    }
  }, [plans, onBackendLog]);

  useEffect(() => {
    onBackendLog({
      type: "planes_opened",
    });

    ReactGA.gtag("event", "paywall_open", {});
  }, [onBackendLog]);

  const isDiscountApplied = localStorage.getItem("discount_applied");
  const total = plans && plans.length > 0 ? plans[selectedPlan].total : "";

  const queryParams = new URLSearchParams(window.location.search);
  const temp_email = queryParams.get("temp_email");

  if (temp_email) {
    localStorage.setItem("payment_email", temp_email);
    queryParams.delete("temp_email");
  }

  return (
    <>
      {isPaymentinProgress && <Loading />}
      <Layout
        pageBG={
          flow === QUESTIONNAIRE_FLOW.WOMAN_FLOW_COMEFIRST_1
            ? PageBG.PLANS_WHITE
            : PageBG.PLANS
        }
      >
        <Header toggler={handleMenuToggle} />
        <Menu toggler={handleMenuToggle} isOpen={meunOpen} />
        <Divider bottom={35} top={15} />
        {isDiscountApplied ? (
          <div className={styles.discount}>
            <div className={styles.discountText}>Get an additional</div>
            <img src={disocuntTitleImg} alt="title discount" />
          </div>
        ) : (
          <>
            <Title text={t(current_flow_text.title)} fontSize={54} />
            <h2>{t(current_flow_text.description)}</h2>
            <Divider bottom={35} top={35} />
            <Results title={t(current_flow_text.results_title)} />
          </>
        )}

        <Divider bottom={35} top={35} />
        <Plan
          plans={plans}
          loading={loading}
          selectedPlan={selectedPlan}
          setSelectedPlan={setSelectedPlan}
        />
        <Divider bottom={59} top={0} />
        {current_flow_text.benefits ? (
          <Benefits benefits={current_flow_text.benefits} />
        ) : (
          <></>
        )}

        {current_flow_text.benefits_extended ? (
          <BenefitsExtended
            benefits_extended={current_flow_text.benefits_extended}
          />
        ) : (
          <></>
        )}

        <Divider bottom={35} top={59} />
        <ReviewsAndRatings reviews={current_flow_text.reviews} />
        <Divider bottom={35} top={35} />
        <MoneyBack />
        <Divider bottom={25} top={35} />

        <button
          className={cls(styles.getMyPlan, { [styles.isWhite]: isWhite })}
          onClick={handlePaymentOpen}
        >
          {t("paywall_get_my_plan")}
        </button>

        <BottomSheet
          open={isPaymentModalOpen}
          onDismiss={handlePaymentClose}
          maxHeight={window.innerHeight - 50}
          initialFocusRef={false}
        >
          <Payment
            plans={plans ? plans : []}
            selectedPlan={selectedPlan}
            isPaymentFailed={isPaymentFailed}
            handleClose={handlePaymentClose}
            total={total}
            setIsPaymentFailed={setIsPaymentFailed}
            setIsPaymentProgress={setIsPaymentProgress}
          />
        </BottomSheet>

        <BottomSheet
          open={isSpecialOfferModalOpen}
          onDismiss={handleSpecialOfferClose}
          maxHeight={window.innerHeight - 50}
          initialFocusRef={false}
        >
          <SpecialOfferModal onClose={handleSpecialOfferClose} />
        </BottomSheet>
      </Layout>
    </>
  );
};

export default Plans;
