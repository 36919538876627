import axios from "axios";
import { sentryLog } from "../helpers/sentryLog";

const HOST = "https://content.the.coach";

const configureApi = (baseURL?: string) => axios.create({ baseURL });

export enum ApiPaths {
  "BRAINTREE_PLANS" = "braintree_plans/",
  "BRAINTREE_TOKEN" = "braintree_token/",
  "BRAINTREE_STATUS" = "/braintree/status/",
  "CANCEl_FEEDBACK" = "/braintree/cancel_feedback",
  "BRAINTREE_SUBSCRIPTION_NOAUTH" = "braintree_subscription_noauth/",
  "SEND_EMAIL" = "deeplink/save_email/",
  "PRE_SALE" = "braintree_subscription_upsell/",
  "CHAT_BOT" = "chatwithcoach/",
  "CHAT_BOT_START" = "/api/v1/aicoach/start",
  "QUESTIONNAIRE_SUBMIT" = "/api/v1/onboarding_questions/",
  "VERIFY_EMAIL" = "/api/v1/user/web/verify_email/",
  "VERIFY_EMAIL_CODE" = "/api/v1/user/web/email_token/",
  "USER_SUBSCRIPTION_DATA" = "/api/v1/user/web/subscription_data",
  "CANCEL_REMINDER" = "/api/v1/user/web/email_payment_reminder",
  "ACCEPT_CANCEL_OFFER" = "/api/v1/user/submit_feedback/",
  "BACKEND_LOG" = "/api/subscriptions/events",
}

const api = configureApi(HOST);

// Add a request interceptor
api.interceptors.request.use(
  (config: any) => {
    // Attach the start time to the config metadata
    (config as any).metadata = { startTime: new Date().getTime() };

    const queryParams = new URLSearchParams(window.location.search);
    const lng = (queryParams.get("lng") || "en").toUpperCase();

    if (config.method?.toUpperCase() === "GET") {
      config.params = {
        ...config.params,
        lng,
      };
    } else {
      // Check if data is FormData and skip modifying it
      if (config.data instanceof FormData) {
        return config;
      }

      if (config.data && typeof config.data === "object") {
        config.data = { ...config.data, lng };
      } else if (typeof config.data === "string") {
        try {
          const parsedData = JSON.parse(config.data);
          if (typeof parsedData === "object" && parsedData !== null) {
            config.data = JSON.stringify({ ...parsedData, lng });
          }
        } catch (error) {}
      }
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add a response interceptor
api.interceptors.response.use((response: any) => {
  const endTime = new Date().getTime();
  const startTime = (response.config as any).metadata.startTime;
  const duration = endTime - startTime;

  sentryLog("Request LOG", {
    url: response.config.url,
    method: response.config.method,
    status: response.status,
    duration: `${duration}ms`,
  });

  return response;
});

export { api };
