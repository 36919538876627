import { FC, useEffect, useState } from "react";
import Layout, { PageBG } from "../../components/Layout";
import QuestionnaireHeader from "../../components/QuestionnaireHeader";
import QuestionnaireTitle from "../../components/QuestionnaireTitle";
import QuestionnaireContent from "../../components/QuestionnaireContent";
import QuestionnaireAnalize from "../../components/QuestionnaireAnalize";
import ReactGA from "react-ga4";
import { man_flow } from "./man_flow";
import { man_flow_no_email } from "./man_flow_no_email";
import { woman_flow } from "./woman_flow";
import { woman_flow_qs } from "./woman_flow_qs";
import { useNavigate } from "react-router-dom";
import useBackendLog from "../../hooks/useBackendLog";
import { useTranslation } from "react-i18next";

export interface IQuestionData {
  value: string;
  text: string;
  img?: string;
}
export interface IQuestionnaireData {
  type: string;
  badge: string;
  title: string;
  description: string;
  questions?: IQuestionData[];
  image?: string;
  Component?: FC;
}

export enum QUESTIONNAIRE_FLOW {
  WOMAN_FLOW_DESIRE_1 = "WOMAN_FLOW_DESIRE_1",
  WOMAN_FLOW_COMEFIRST_1 = "WOMAN_FLOW_COMEFIRST_1",
  MAN_FLOW = "MAN_FLOW",
  MAN_FLOW_NO_EMAIL = "MAN_FLOW_NO_EMAIL",
}

export enum QUESTIONNAIRE_THEME {
  WHITE = "WHITE",
  GREEN = "GREEN",
}

export const getQuestionanireFlow = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const qs = queryParams.get("qs");

  if (qs === "m_no_email") {
    return QUESTIONNAIRE_FLOW.MAN_FLOW_NO_EMAIL;
  }

  if (!window.location.href.includes("forher.the.coach")) {
    return QUESTIONNAIRE_FLOW.MAN_FLOW;
  }

  if (qs === "desire1") {
    return QUESTIONNAIRE_FLOW.WOMAN_FLOW_DESIRE_1;
  }

  if (qs === "comefirst1") {
    return QUESTIONNAIRE_FLOW.WOMAN_FLOW_COMEFIRST_1;
  }

  return QUESTIONNAIRE_FLOW.MAN_FLOW;
};

export const getQuestionnaireTheme = () => {
  const flow = getQuestionanireFlow();

  switch (flow) {
    case QUESTIONNAIRE_FLOW.WOMAN_FLOW_COMEFIRST_1:
      return QUESTIONNAIRE_THEME.WHITE;
    default:
      return QUESTIONNAIRE_THEME.GREEN;
  }
};

const Questionnaire: FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { onBackendLog } = useBackendLog();

  useEffect(() => {
    onBackendLog({
      type: "questionnaire_opened",
    });

    ReactGA.gtag("event", "qs_open", {});
  }, [onBackendLog]);

  const questionaireDataStorage = localStorage.getItem("questionnaire_data");
  const questionaireData = questionaireDataStorage
    ? JSON.parse(questionaireDataStorage)
    : { current: 0 };

  const [current, setCurrent] = useState<number>(questionaireData.current);

  let data = man_flow;

  const flow = getQuestionanireFlow();
  const theme = getQuestionnaireTheme();

  useEffect(() => {
    if (data.length < current) {
      localStorage.removeItem("questionnaire_data");
      setCurrent(0);
    }
  }, [data.length, current]);

  if (flow === QUESTIONNAIRE_FLOW.WOMAN_FLOW_DESIRE_1) {
    data = woman_flow;
  }

  if (flow === QUESTIONNAIRE_FLOW.WOMAN_FLOW_COMEFIRST_1) {
    data = woman_flow_qs;
  }

  if (flow === QUESTIONNAIRE_FLOW.MAN_FLOW_NO_EMAIL) {
    data = man_flow_no_email;
  }

  const setStorageData = (key: string, value: unknown) => {
    switch (key) {
      case "current":
        questionaireData.current = value as number;
        break;
    }

    localStorage.setItem(
      "questionnaire_data",
      JSON.stringify(questionaireData)
    );
  };

  if (data.length < current) return null;

  const currentQuestion = data[current];

  if (!currentQuestion) {
    localStorage.removeItem("questionnaire_data");
    setCurrent(0);
    return null;
  }

  const onNextQuestion = (answer: string) => {
    ReactGA.gtag("event", "question_chosen_" + (current + 1), {
      question_number: current + 1,
      answer: answer,
    });

    if (current === data.length - 1) {
      onBackendLog({
        type: "on_plans_redirect",
      });
      navigate("/plans" + window.location.search);
    } else {
      setCurrent((prev) => {
        setStorageData("current", prev + 1);
        return prev + 1;
      });
    }
  };

  const onPrevQuestion = () => {
    setCurrent((prev) => {
      setStorageData("current", prev - 1);
      return prev - 1;
    });
  };

  return (
    <Layout
      pageBG={
        theme === QUESTIONNAIRE_THEME.WHITE
          ? PageBG.QUESTIONNAIRE_WHITE
          : PageBG.QUESTIONNAIRE
      }
    >
      {currentQuestion.type === "analyze" ? (
        <QuestionnaireAnalize onNextQuestion={onNextQuestion} />
      ) : (
        <>
          <QuestionnaireHeader
            current={current}
            total={data.length}
            onPrevQuestion={onPrevQuestion}
            badge={
              currentQuestion.type === "email"
                ? `${t("main_male_questionnaire_question_label_1")} ${data.length}`
                : currentQuestion.badge
                  ? t(currentQuestion.badge)
                  : `${t("main_male_questionnaire_question_label_1")} ${current + 1}`
            }
          />
          <QuestionnaireTitle
            title={t(currentQuestion.title)}
            description={t(currentQuestion.description)}
          />

          <QuestionnaireContent
            question={currentQuestion}
            onNextQuestion={onNextQuestion}
          />
        </>
      )}
    </Layout>
  );
};

export default Questionnaire;
