import { FC, useEffect, useState } from "react";
import braintree, { Client } from "braintree-web";
import styles from "./styles.module.scss";
import { ReactComponent as ApplePay } from "./../../../assets/svg/apple-pay.svg";
import * as Sentry from "@sentry/react";
import useBackendLog from "../../hooks/useBackendLog";

interface IProps {
  braintreeClientInstance: Client;
  onPaymentMethodReceived: (nonce: string) => void;
  total: string;
  setIsPaymentProgress: React.Dispatch<React.SetStateAction<boolean>>;
}

const BraintreeApplePay: FC<IProps> = ({
  braintreeClientInstance,
  onPaymentMethodReceived,
  setIsPaymentProgress,
  total,
}) => {
  const { onBackendLog } = useBackendLog();

  const [paymentInstance, setPaymentInstance] =
    useState<braintree.ApplePay | null>(null);

  const createPaymentRequest = (total: string) =>
    paymentInstance &&
    paymentInstance.createPaymentRequest({
      total: {
        label: "The Coach",
        amount: total,
      },
    });

  useEffect(() => {
    if (braintreeClientInstance && isApplePayAvailable()) {
      braintree.applePay.create(
        {
          client: braintreeClientInstance,
        },
        (applePayErr, applePayInstance) => {
          if (applePayErr) {
            Sentry.captureException(applePayErr);
            return;
          }

          if (applePayInstance) {
            setPaymentInstance(applePayInstance);
          }
        }
      );
    }
  }, [braintreeClientInstance]);

  const isApplePayAvailable = () => {
    return (
      //@ts-ignore
      window.ApplePaySession &&
      ApplePaySession.supportsVersion(3) &&
      ApplePaySession.canMakePayments()
    );
  };

  if (!isApplePayAvailable()) {
    return null;
  }

  const onAppleButtonClick = async () => {
    onBackendLog({
      type: "payment_form_loaded",
    });

    setIsPaymentProgress(true);
    const request = createPaymentRequest(`${+total.replace(/[^0-9.]/g, "")}`);

    if (request && paymentInstance) {
      //@ts-ignore
      const session = new ApplePaySession(3, request);
      session.onvalidatemerchant = function (event) {
        paymentInstance.performValidation(
          {
            validationURL: event.validationURL,
            displayName: "The coach",
          },
          function (validationErr, validationData) {
            if (validationErr) {
              console.error(validationErr);
              Sentry.captureException(validationErr);
              session.abort();
              setIsPaymentProgress(false);
              return;
            }
            session.completeMerchantValidation(validationData);
          }
        );
      };
      session.onpaymentauthorized = function (event) {
        paymentInstance.tokenize(
          {
            token: event.payment.token,
          },
          function (tokenizeErr, tokenizedPayload) {
            if (tokenizeErr) {
              Sentry.captureException(tokenizeErr);
              session.completePayment(ApplePaySession.STATUS_FAILURE);
              setIsPaymentProgress(false);
              return;
            }

            if (tokenizedPayload) {
              onPaymentMethodReceived(tokenizedPayload.nonce);
            }

            session.completePayment(ApplePaySession.STATUS_SUCCESS);
          }
        );
      };

      session.oncancel = function (event) {
        setIsPaymentProgress(false);
      };
      session.begin();
    }
  };

  return (
    <div className={styles.wrapper}>
      <button className={styles.button} onClick={onAppleButtonClick}>
        <ApplePay />
      </button>
    </div>
  );
};

export default BraintreeApplePay;
